<template>
  <div>
    <section class="white">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <h2
              :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
              class="mb-4"
            >
              Design, perfection & quality ™
            </h2>

            <p class="subtitle-1 text-justify">
              Nasza pasja do tworzenia przejawia się w wyjątkowej architekturze projektów o praktycznych rozwiązaniach, które charakteryzuje perfekcja i wysoka jakość wykonania.
            </p>
            <p class="mb-5 text-justify">
              Oferujemy kompleksowe remonty, adaptacje i wykończenia wnętrz pod klucz. Dysponujemy wysoce wykwalifinowanym zespołem pracowników, a projekty wykonujemy przy użyciu najlepszych materiałów renomowanych firm. Podczas realizaji inwestycji zapewniamy nadzór wykonawczy, budowlany oraz kierownictowo, wsparcie i doradztwo naszego zespołu. Na bieżąco kontrolujemy jakość wykonywanych robót, użytych materiałów, a także dokumentację rozliczeniową.
            </p>
            <p class="mb-5 text-justify">
              Prowadzimy zarówno małe (pojedyncze mieszkania) jak i duże (kamienice, budynki jednorodzinne i wielorodzinne) realizacje dla inwestorów bezpośrednich, finansujących przedsięwzięcie. Jako inwestor zastępczy gwarantujemy kompleksowe zorganizowanie i koordynację inwestycji przejmując wszystkie działania, procesy i obowiązki związane z procesem budowlanym w tym dopełnienie wszelkich formalności. Wykonujemy projekty, organizujemy przetargi, zakup materiałów i urządzeń. Kontraktujemy poszczególnych wykonawców robót budowlanych i innych usług niezbędnych do realizacji i finalizacji inwestycji takich jak marketing oraz profesjonalna sprzedaż.
            </p>
          </v-col>
          <v-col
            cols="12"
            md="5"
            offset-md="1"
          >
            <v-card>
              <v-img src="/static/outside.jpg" />
            </v-card>
            <br>
            <v-card>
              <v-img src="/static/inside.jpg" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      highlights: [
        'Projekty i architektura',
        'Wykończenia wnętrz',
        'Inwestor zastępczy',
        'Wykonawstwo i nadzory',
        'Marketing',
        'Sprzedaż'
      ],
      experiences: [
        ['mdi-vuejs', '75', 'Vue'],
        ['mdi-vuetify', '100', 'Vuetify'],
        ['mdi-aws', '67', 'AWS'],
        ['mdi-server-security', '95', 'Security']
      ]
    })
  }
</script>
